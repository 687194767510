import axios from "axios";

export default {
  data: () => ({
    basicCount: 0,
    legendCount: 0,
    search_type: "email",
    search_types: [
      { title: "Instagram", value: "instagramId" },
      { title: "TikTok", value: "tiktokId" },
      { title: "Phone", value: "phone" },
      { title: "Email", value: "email" },
    ],
    edit_mode: false,
    search_text: "",
    user: {},
    suggests: [],
    selected_suggests: "",
    combo_loading: false,

    name: "",
    email: "",
    phone: "",
    level: 1,
    levelOpt: [
      { text: "Basic", value: 1 },
      { text: "Legend", value: 2 },
    ],
    instagram_id: "",
    tiktok_id: "",
    instagram_followers: "",
    tiktok_followers: "",
    country: "",
    city: "",

    user_id: "",
  }),

  mounted() {
    this.user_id = localStorage.getItem("userId");
    this.get_count();
    if (this.user_id) {
      this.get_user();
    }
  },

  methods: {
    async get_count() {
      await axios({
        method: "GET",
        url: "/admin/customers/all",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.basicCount = res.data.data.basicCount;
          this.legendCount = res.data.data.legendCount;
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          // this.$store.commit('progress_status', false)
        });
    },
    async get_suggests() {
      if (this.search_text && this.search_text.length > 1) {
        this.suggests = [];
        console.log(this.search_text);
        // this.$store.commit('progress_status', true)
        this.combo_loading = true;
        await axios({
          method: "GET",
          url: "/admin/customers/search",
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
          },
          params: {
            field: this.search_type,
            value: this.search_text,
          },
        })
          .then((res) => {
            console.log(res.data.data.customers);
            this.suggests = res.data.data.customers;
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
          })
          .finally(() => {
            // this.$store.commit('progress_status', false)
            this.combo_loading = false;
          });
      }
    },

    // when mount page with userId(localStoraage)
    async get_user() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/admin/customers/" + this.user_id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res.data);
          this.user = res.data.data.customer;

          this.name = this.user.name;
          this.email = this.user.email;
          this.phone = this.user.phone;
          this.level = this.user.level;
          this.instagram_id = this.user.instagramId.replace(
            "https://instagram.com/",
            ""
          );
          this.tiktok_id = this.user.tiktokId
            ? this.user.tiktokId.replace("https://tiktok.com/", "")
            : "";
          this.country = this.user.country;
          this.city = this.user.city;
          (this.instagram_followers = this.user.instagramFollowersCount),
            (this.tiktok_followers = this.user.tiktokFollowersCount),
            localStorage.setItem("userId", this.user._id);
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    // when select from comboBox
    async search() {
      console.log("search");
      console.log(this.search_text);
      if (this.search_text.length < 2) {
        this.suggests = [];
      } else {
        this.$store.commit("progress_status", true);
        await axios({
          method: "GET",
          url: "/admin/customers/" + this.selected_suggests._id,
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
          },
        })
          .then((res) => {
            console.log(res.data);
            this.user = res.data.data.customer;

            this.name = this.user.name;
            this.email = this.user.email;
            this.phone = this.user.phone;
            this.instagram_id = this.user.instagramId.replace(
              "https://instagram.com/",
              ""
            );
            this.tiktok_id = this.user.tiktokId
              ? this.user.tiktokId.replace("https://tiktok.com/", "")
              : "";
            this.country = this.user.country;
            this.city = this.user.city;
            (this.instagram_followers = this.user.instagramFollowersCount),
              (this.tiktok_followers = this.user.tiktokFollowersCount),
              localStorage.setItem("userId", this.selected_suggests._id);
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
          })
          .finally(() => {
            this.$store.commit("progress_status", false);
          });
      }
    },

    async suspend(type) {
      this.$store.commit("progress_status", true);
      await axios({
        method: "PATCH",
        url: "/admin/customers/" + this.user._id + "/suspend",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        data: {
          action: type,
        },
      })
        .then((res) => {
          console.log(res.data);
          if (type === "unblock") {
            this.$store.commit("snackbar", {
              color: "green",
              text: "Customer unblocked successfully.",
            });
          } else {
            this.$store.commit("snackbar", {
              color: "green",
              text: "Customer blocked successfully.",
            });
          }
          this.get_user();
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    validation() {
      if (
        !this.name ||
        !this.email ||
        !this.phone ||
        !this.instagram_id ||
        !this.country ||
        !this.city
      ) {
        this.$store.commit("snackbar", {
          color: "red",
          text: "Please fill out the form correctly.",
        });
      } else if (!this.email.includes(".") || !this.email.includes("@")) {
        this.$store.commit("snackbar", {
          color: "red",
          text: "Email address is not valid.",
        });
      } else if (this.phone.length !== 10) {
        this.$store.commit("snackbar", {
          color: "red",
          text: "Phone number is not valid.",
        });
      } else {
        this.save();
      }
    },

    async save() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "PUT",
        url: "/admin/customers/" + this.user._id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        data: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          level: this.level,
          instagramId: "https://instagram.com/" + this.instagram_id,
          tiktokId: this.tiktok_id
            ? "https://tiktok.com/" + this.tiktok_id
            : "",
          country: this.country,
          city: this.city,
          instagramFollowersCount: this.instagram_followers,
          tiktokFollowersCount: this.tiktok_followers,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.edit_mode = false;
          this.$store.commit("snackbar", {
            color: "green",
            text: "User saved successfully.",
          });
        })
        .catch((err) => {
          console.log(err);
          this.name = this.user.name;
          this.email = this.user.email;
          this.phone = this.user.phone;
          this.instagram_id = this.user.instagramId.replace(
            "https://instagram.com/",
            ""
          );
          this.tiktok_id = this.user.tiktokId.replace(
            "https://tiktok.com/",
            ""
          );
          this.country = this.user.country;
          this.city = this.user.city;
          (this.instagram_followers = this.user.instagramFollowersCount),
            (this.tiktok_followers = this.user.tiktokFollowersCount),
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    open_link(link) {
      window.open(link, "_blank");
    },

    change_mode() {
      this.edit_mode = !this.edit_mode;
    },
  },
};
