import login from "./pages/login/login.vue";
import stats from "./pages/stats/stats.vue";
import find_user from "./pages/find_user/find_user.vue";
import wallet from "./pages/wallet/wallet.vue";
import activities from "./pages/activities/activities.vue";
import new_business from "./pages/new_business/new_business.vue";
import find_business from "./pages/find_business/find_business.vue";
import all_business from "./pages/all_business/all_business.vue";
import business_campaigns from "./pages/business_campaigns/business_campaigns.vue";
import campaign_details from "./pages/campaign_details/campaign_details.vue";
import new_venues from "./pages/new_venues/new_venues.vue";
import find_venue from "./pages/find_venue/find_venue.vue";
import payments from "./pages/payments/payments.vue";
import venue_campaigns from "./pages/venue_campaigns/venue_campaigns.vue";
import menu from "./pages/menu/menu.vue";
import entertainment from "./pages/entertainment/entertainment.vue";
import campaigns from "./pages/campaigns/campaigns.vue";
import live_campaigns from "./pages/live_campaigns/live_campaigns.vue";
import settings from "./pages/settings/settings.vue";
import coordinates from "./pages/coordinates/coordinates.vue";
import blocked_users from "./pages/blocked_users/blocked_users.vue";
import blocked_venues from "./pages/blocked_venues/blocked_venues.vue";
import blocked_business from "./pages/blocked_business/blocked_business.vue";
import business_deposit from "./pages/business_deposit/business_deposit.vue";
import venue_deposit from "./pages/venue_deposit/venue_deposit.vue";
import questions from "./pages/questions/questions.vue";
import withdraw from "./pages/withdraw/withdraw.vue";
import withdraw_info from "./pages/withdraw_info/withdraw_info.vue";
import new_users from "./pages/new_users/new_users.vue";
import rejected_venues from "./pages/rejected_venues/rejected_venues.vue";
import accounting from "./pages/accounting/accounting.vue";

export const Routes = [
  {
    path: "/",
    redirect: "/login",
  },

  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/stats",
    name: "stats",
    component: stats,
  },
  {
    path: "/find_user",
    name: "find_user",
    component: find_user,
  },
  {
    path: "/user_wallet",
    name: "wallet",
    component: wallet,
  },
  {
    path: "/user_activities",
    name: "activities",
    component: activities,
  },
  {
    path: "/new_business",
    name: "new_business",
    component: new_business,
  },
  {
    path: "/find_business",
    name: "find_business",
    component: find_business,
  },
  {
    path: "/all_business",
    name: "all_business",
    component: all_business,
  },
  {
    path: "/business_campaigns",
    name: "business_campaigns",
    component: business_campaigns,
  },
  {
    path: "/campaign_details",
    name: "campaign_details",
    component: campaign_details,
  },
  {
    path: "/new_venues",
    name: "new_venues",
    component: new_venues,
  },
  {
    path: "/find_venue",
    name: "find_venue",
    component: find_venue,
  },
  {
    path: "/venue_payments",
    name: "payments",
    component: payments,
  },
  {
    path: "/venue_campaigns",
    name: "venue_campaigns",
    component: venue_campaigns,
  },
  {
    path: "/venue_menu",
    name: "menu",
    component: menu,
  },
  {
    path: "/venue_entertainment",
    name: "entertainment",
    component: entertainment,
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: campaigns,
  },
  {
    path: "/live_campaigns",
    name: "live campaigns",
    component: live_campaigns,
  },
  {
    path: "/settings",
    name: "settings",
    component: settings,
  },
  {
    path: "/coordinates",
    name: "coordinates",
    component: coordinates,
  },
  {
    path: "/blocked_users",
    name: "blocked_users",
    component: blocked_users,
  },
  {
    path: "/blocked_venues",
    name: "blocked_venues",
    component: blocked_venues,
  },
  {
    path: "/blocked_business",
    name: "blocked_business",
    component: blocked_business,
  },
  {
    path: "/business_deposit",
    name: "business_deposit",
    component: business_deposit,
  },
  {
    path: "/venue_deposit",
    name: "venue_deposit",
    component: venue_deposit,
  },
  {
    path: "/questions",
    name: "questions",
    component: questions,
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: withdraw,
  },
  {
    path: "/withdraw_info",
    name: "withdraw_info",
    component: withdraw_info,
  },
  {
    path: "/new_users",
    name: "new_users",
    component: new_users,
  },
  {
    path: "/rejected_venues",
    name: "rejected_venues",
    component: rejected_venues,
  },
  {
    path: "/accounting",
    name: "accounting",
    component: accounting,
  },
];
