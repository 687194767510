import axios from "axios";

export default {
  data: () => ({
    count: 0,
    users: [],
    pagination: {
      page: 1,
      pages: 0,
      perPage: 10,
    },
  }),

  mounted() {
    this.get_users();
  },

  methods: {
    async get_users() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/admin/businesses",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.users = res.data.data.users;
          this.count = res.data.data.count;
          this.pagination.pages = Math.ceil(res.data.data.count / 10);
        })
        .catch((err) => {
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    go_user(userId) {
      localStorage.setItem("businessId", userId);
      this.$router.push({ path: "/find_business" });
    },

    async next(page) {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/admin/businesses",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        params: { page },
      })
        .then((res) => {
          this.users = res.data.data.users;
        })
        .catch((err) => {
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },
  },
};
